<script>
import {getTemplate,sendEmail} from '@/apis/email'
import {formatEmailInfo} from '@/apis/common'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {downFile} from '@/apis/common'
import {getNetDiskFile} from '@/apis/disk'
import store from '@/state/store'
export default {
    props:{
        templateId: String,
        data: Object
    },
    components: { ckeditor: CKEditor.component },
    data() {
        return {
            language:"zh-CN",
            emailForm:{
                to:"",
                cc:"",
                bcc:"",
                subject:"",
                content:"",
                attachment:"",
            },
            orgAttachments:[],
            template:{},
            editor: ClassicEditor,
        };
    },
    computed: {
    },
    watch:{
        templateId(){
            this.loadTemplate();
        },
        data:{
            handler(){
                this.updateEmail()
            },
            deep:true
        }
    },
    created(){
        
    },
    mounted() {
        this.loadTemplate();
    },
    methods: {
        formatEmailInfo,    
        loadTemplate(){
            getTemplate(this.templateId).then((res)=>{
                this.template = res.data,
                this.updateEmail()
            });
        },
        updateEmail(){
            this.emailForm = JSON.parse(JSON.stringify(this.data));
            this.formatEmailInfo(this.language,this.emailForm,this.data.emailData,this.template);
            if(this.emailForm.content.indexOf("{signature}")!=-1){
                let signature = ""
                if(store.state.auth.currentUser.signature!=undefined && store.state.auth.currentUser.signature!=null){
                    signature = store.state.auth.currentUser.signature
                }
                this.emailForm.content = this.emailForm.content.replace("{signature}",signature)
            }
            if(this.template.attachment!=null && this.template.attachment!=""){
                let attachments = []
                if(this.language=="zh-CN"){
                    attachments = this.template.attachment.split(",")
                }else if(this.language=="en-US"){
                    attachments = this.template.attachmentEn.split(",")
                }else if(this.language=="vn"){
                    attachments = this.template.attachmentVn.split(",")
                }
                if(attachments.length>0){
                    getNetDiskFile({code:attachments}).then((res)=>{
                        for(let f=0;f< res.data.length;f++){
                            this.emailForm.attachment.push({name:res.data[f].fileName,file:res.data[f].fileOrgName})
                        }
                    })
                }
            }
        },
        handleSendEmail(){
            let params = {
                to:this.emailForm.to,
                cc:this.emailForm.cc,
                bcc:this.emailForm.bcc,
                subject:this.emailForm.subject,
                content:this.emailForm.content,
                
            }
            if(this.emailForm.attachment!=null && this.emailForm.attachment!=undefined){
                params.attachment = this.emailForm.attachment.map((item)=>{return item.file}).join(",")
            }
            sendEmail(params).then((res)=>{
                if(res.data==true){
                    this.$emit("send",{code:1})
                }
            }).catch((res)=>{
                this.$emit("send",{code:2,data:res})
            })
        },
        handleCancel(){
            this.$emit("send",{code:0})
        },
        handleDownload (item){
            const url=`/api-gateway/service/download?fileType=ATT&fileName=${item.file}`
            downFile(url,item.fileName);
        },
        handleChangeLanguage(language){
            this.language = language
            this.updateEmail()
        }
    }
};
</script>

<template>
    <div class="row">
        <div>
            <div class="form-row justify-content-end mb-3">
                <div role="group" class="btn-group btn-group-sm">
                    <button type="button" class="btn " v-bind:class="{ 'btn-info': language=='zh-CN', 'btn-secondary': language!='zh-CN' }" @click="handleChangeLanguage('zh-CN')">中文</button>
                    <button type="button" class="btn " v-bind:class="{ 'btn-info': language=='en-US', 'btn-secondary': language!='en-US' }" @click="handleChangeLanguage('en-US')">英文</button>
                    <button type="button" class="btn " v-bind:class="{ 'btn-info': language=='vn', 'btn-secondary': language!='vn' }" @click="handleChangeLanguage('vn')">越南语</button>
                </div>
            </div>
            <div class="form-row form-group mb-3">
                <label class="col-lg-2 col-sm-6 col-form-label form-label" for="gen-info-to-input">{{$t("email.to")}}</label>
                <div class="col-lg-10 col-sm-6">
                    <input type="text" class="form-control" id="gen-info-to-input" v-model="emailForm.to">
                </div>
            </div>
            <div class="form-row form-group mb-3">
                <label class="col-lg-2 col-sm-6 col-form-label form-label" for="gen-info-cc-input">{{$t("email.cc")}}</label>
                <div class="col-lg-10 col-sm-6">
                    <input type="text" class="form-control" id="gen-info-cc-input" v-model="emailForm.cc">
                </div>
            </div>
            <div class="form-row form-group mb-3">
                <label class="col-lg-2 col-sm-6 col-form-label form-label" for="gen-info-bcc-input">{{$t("email.bcc")}}</label>
                <div class="col-lg-10 col-sm-6">
                    <input type="text" class="form-control" id="gen-info-bcc-input" v-model="emailForm.bcc">
                </div>
            </div>
            <div class="form-row form-group mb-3">
                <label class="col-lg-2 col-sm-6 col-form-label form-label" for="gen-info-subject-input">{{$t("email.subject")}}</label>
                <div class="col-lg-10 col-sm-6">
                    <input type="text" class="form-control" id="gen-info-subject-input" v-model="emailForm.subject">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 mb-3">
                        <ckeditor v-model="emailForm.content" :editor="editor"></ckeditor>
                </div>
            </div>
            
            <div class="row" v-if="emailForm.attachment!=undefined">
                <div class="col-lg-12 mb-3">
                        <label class="form-label" for="gen-info-attach-input">{{$t("email.attachment")}}</label>
                        <div class="d-flex gap-2"><a href="javascript:" @click="handleDownload(attach)" class="badge badge-soft-primary" v-for="attach in emailForm.attachment" :key="attach.file">{{attach.name}}</a></div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary w-sm" @click="handleSendEmail">{{$t("actions.send")}}</button>
            <button type="button" class="btn btn-success w-sm ms-1" @click="handleCancel">{{$t("actions.cancel")}}</button>
        </div>   
    </div>
</template>