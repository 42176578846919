<script>
import {lookup2,downFile,getLookupItemText} from '@/apis/common'
import {uploadFile} from '@/apis/file'
import {setOrderStatus,queryQuotation} from '@/apis/order'
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import Vue from "vue";
import {
  required
} from "vuelidate/lib/validators";
export default {
    props:{
        data:Object,
        show: {
            type:Boolean,
            default:false
        }
    },
    validations: {
        form: {
            cancelType: { 
                required,
            },
        }
    },
    data(){
        return {
            status:null,
            file:null,
            lookupRawData:{},
            orderStatus:[],
            cancelTypeList:[],
            cancelTypes:[],
            submitted:false,
            form:{
                cancelType:"",
                cancelReason:"",
            },            
            order:null,
            cancelSetting:false
        }
    },
    components: {Multiselect},
    computed:{
        orderData(){
            if(this.data==null||this.data==undefined){
                return {orderNo:"",clientName:""}
            }else{
                return this.data
            }
        },
    },
    watch:{
        show(newValue){
            if(newValue){
                this.order = null
                this.$refs.orderstatusModal.show()
            }else{
                this.$refs.orderstatusModal.hide()
            }
        },
        "cancelTypes"(){
            if(this.cancelTypes.value!=undefined){
                this.form.cancelType = this.cancelTypes.value
            }
        },
    },
    created(){
        this.init()
    },
    methods:{
        async init(){
            this.lookupRawData = await lookup2("ORDER-STATUS,CANCEL-TYPE");
            this.orderStatus = this.lookupRawData["ORDER-STATUS"];
            this.cancelTypeList =  this.lookupRawData["CANCEL-TYPE"];
        },
        bindClass(status){
            let classObject = {"step-item-complate":false}
            let statusRule = {
                "N":['N'],
                "FU":['N','FU'],
                "Q":['N','FU',"Q"],
                "P":['N','FU','Q',"P"],
                "F":['N','FU','Q','P',"F"],
                "C":['N','FU','Q',"C"],
            }
            if(this.orderData.orderStatus!=undefined && statusRule[this.orderData.orderStatus].includes(status)){
                classObject = {"step-item-complate":true}
            }
            return classObject;
        },
        showAction(status){
            let statusRule = {
                "FU":['N'],
                "Q":['N','FU'],
                "P":['N','FU','Q'],
                "F":['N','FU','Q','P'],
                "C":['N','FU','Q'],
            }
            if(status!='N' && statusRule[status].includes(this.orderData.orderStatus)){
                return true;
            }else{
                return false;
            }
        },
        enableAction(status){
            let statusRule = {
                "FU":['N'],
                "Q":['N','FU'],
                "P":['Q'],
                "F":['P'],
                "C":['N','FU','Q'],
            }
            if(statusRule[status].includes(this.orderData.orderStatus)){
                return true;
            }else{
                return false;
            }
        },
        hanleClose(){
            this.$emit('close', this.order)
        },
        handleSetOrderStatus(status){
            Swal.fire({
                title: this.$t("order.infoSure"),
                text: this.$t("order.infoOperation"),
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: this.$t("actions.ok"),
                cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
                if (result.value) {
                    this.changeOrderStatus(status,null)
                }
            });
        },
        changeOrderStatus(status,attachment){
            let cancelType = ""
            let cancelReason = ""
            if(status=='C'){
                cancelType = this.form.cancelType
                cancelReason = this.form.cancelReason
            }
            setOrderStatus({orderNo:this.orderData.orderNo,status:status,attachment:attachment,cancelType:cancelType,cancelReason:cancelReason}).then((res)=>{
                this.status = status
                this.order = res.data
                this.$refs.orderstatusModal.hide()
                this.$refs.cancelModal.hide()     
            }).catch(()=>{
                Vue.swal(this.$t("order.errorUpdate"));
            })
        },
        handleAction(status){
            if(status=="Q"){
                if(this.orderData.clientId==undefined || this.orderData.clientId==null){
                    Vue.swal(this.$t("order.infoBindAlert"));
                }else{
                    const params = {filter:{orderId:this.orderData.orderId}};
                    queryQuotation(params).then((res)=>{
                        if(res.page.total==0){
                            Vue.swal(this.$t("order.infoQuoteAlert"));
                        }else{
                            this.handleSetOrderStatus(status)
                        }
                    });
                }
            }else if(status=="P"){
                Swal.fire({
                    title: this.$t("order.infoSure"),
                    text: this.$t("order.infoPaymentStatus"),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: this.$t("actions.ok"),
                    cancelButtonText: this.$t("actions.cancel")
                }).then(result => {
                    if (result.value) {
                        this.$refs.attachment.click()
                    }
                });                
            }else if(status=="C"){
                this.$refs.cancelModal.show()     
            }else{
                this.handleSetOrderStatus(status)
            }
        },
        handleCancel(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                this.changeOrderStatus('C',null)
            }
        },
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },
        showText(item,textType){
            let status = item.value;
            let statusText = localStorage.getItem('language')!="zh-CN"?item.labelEn:item.label;
            if(textType==1){
                if(status=='Q' && this.orderData.quotationDate!=null ){
                    statusText += " - " + this.orderData.quotationDate
                }else if(status=='P' && this.orderData.paymentDate!=null){
                    statusText += " - " + this.orderData.paymentDate
                }else if(status=='C' && this.orderData.cancelType!=null){
                    statusText += " - " + this.formatLookupText('CANCEL-TYPE',this.orderData.cancelType)
                    if(this.orderData.cancelReason!=null&&this.orderData.cancelReason!=""){
                        statusText += "("+this.orderData.cancelReason+")" 
                    }
                }
            }
            return statusText
        },
        changeFile(){
            if(this.$refs.attachment.files.length==1){
                const formData = new FormData();
                formData.append("files",this.$refs.attachment.files[0]);
                uploadFile(formData).then((res)=>{
                    let paymentVoucher = res.data[0].fileOrgName
                    this.changeOrderStatus('P',paymentVoucher)
                })
            }
        },
        handleDownload (){
            const url=`/api-gateway/service/download?fileType=ATT&fileName=${this.orderData.paymentVoucher}`
            let fileInfo = this.orderData.paymentVoucher.split(".");
            downFile(url,this.$t("order.paymentVoucher")+fileInfo[fileInfo.length-1]);
        },
    }
};
</script>
<template>
    <div v-show="show">
    <b-modal
    id="modal-center"
    ref="orderstatusModal"
    centered
    :title='$t("order.statusSettings")'
    title-class="font-18"
    hide-footer
    @hidden="hanleClose"
    >
    <p><strong>{{orderData.orderNo}} - {{ orderData.clientName }}</strong></p>
        <div class="my-steps">
            <div class="step-item" :class="bindClass(item.value)" :style="{height: '40px'}" v-for="(item,index) in orderStatus" :key="item.value">
                <div class="step-line d-flex align-items-center"><span>{{index+1}}</span></div>
                <div class="step-text d-flex align-items-center">
                    <span v-if="showAction(item.value)==false">{{showText(item,1)}}<a v-if="item.value=='P' && orderData.paymentVoucher&''!=''" href="javascript:" @click="handleDownload()" class="badge badge-soft-primary ms-2" >{{$t("order.paymentVoucher")}}</a></span>
                    <div>
                        <button v-if="showAction(item.value)==true" :disabled="!enableAction(item.value)"  type="button" class="btn btn-primary btn-sm" @click="handleAction(item.value)">{{showText(item,2)}}</button> 
                    </div>                  
                </div>
            </div>
            <input ref="attachment" type="file" accept="image/*" aria-label="Upload your profile picture" placeholder="" style="display: none;" @change="changeFile">
        </div>
    </b-modal>
    <b-modal
        id="cancelModal"
        ref="cancelModal"
        centered
        :title='$t("actions.cancelReason")'
        title-class="font-18"
        hide-footer
        >
        <form class="needs-validation">
            <div>
                <multiselect v-model="cancelTypes" :options="cancelTypeList" placeholder="Type"
                                                label="label"
                                    track-by="value"
                :multiple="false"
                :class="{
                                          'is-invalid': submitted && $v.form.cancelType.$error,
                                      }"
                ></multiselect>
                <div
                    v-if="submitted && $v.form.cancelType.$error"
                    class="invalid-feedback"
                    >
                        <span v-if="!$v.form.cancelType.required">
                        {{$t("common.requiredError")}}.
                        </span>
                </div>
            </div>
            <div>
                <textarea rows="3" class="form-control" v-model="form.cancelReason"></textarea>
            </div>
        </form>        
        <div>
            <button type="button" class="btn btn-primary btn-sm" @click="handleCancel">{{$t("common.ok")}}</button>
        </div>
    </b-modal>    
</div>
</template>
<style lang="scss">
    .my-steps{
        display: flex;
        flex-direction: column;
        .step-item{
            display: flex;
            .step-line{
                background-color: #bfcbd9;
                margin-left: 15px;
                margin-right: 15px;
                width: 3px;
                position: relative;
                span{
                    border-radius: 100%;
                    border: #bfcbd9 solid 1px;
                    background-color: #bfcbd9;
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    left: -6px;
                }
            }
        }
        .step-item-complate{
            color: #327ab7;
            font-weight: bold;
            .step-line{
                background-color: #327ab7;
                span{
                    border: #327ab7 solid 1px;
                    background-color: #327ab7;
                    color: white;
                }
            }
        }
    }
</style>